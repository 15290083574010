<template>
  <div class="page-content">
    <div class="container">
      <div class="list-employee red-theme">
        <div class="row">
          <div class="col-12">
            <h3 class="list-employee__title">
              Список приглашений к сотрудничеству
            </h3>
            <p
              class="list-employee__desc"
            >
              <template v-if="!isCompanyEmployee">
                Вы можете ознакомиться с предложениями о сотрудничестве, тех организаций, которых заинтересовал ваш аккаунт
              </template>
              <template v-else>
                Вы уже являетесь сотрудником компании. Для того, чтобы получать предложения от других компаниий, необходимо покинуть текущую.
              </template>
            </p>
          </div>
        </div>
        <template v-if="loading">
          <v-progress-linear
            indeterminate
          ></v-progress-linear>
        </template>
        <template v-else>
          <template v-if="!totalCountPages && !isCompanyEmployee">
            <p class="list-employee__desc">Нет новых предложений.</p>
          </template>
          <template v-else-if="!isCompanyEmployee">
            <div class="list-employees">
              <template v-for="(item, i) in inviteList">
                <div
                  class="list-employees__item"
                  :key="i"
                >
                  <div class="employees-left">
                    <div class="employees-content">
                      <p class="employee-desc">Название организации</p>
                      <span class="employee-name">{{ item.from_company.name }}</span>
                    </div>
                  </div>
                  <div class="employees-right">
                    <div class="btn-wrapp">
                      <router-link
                        :to="{ name: $routeName.ASSISTANCE_PROVIDE_INVITATION, params: { inviteId: item.id }}"
                      >
                        <button class="employee-btn-details">Подробнее</button>
                      </router-link>
                      <button
                        @click.stop="rejectInvite(item.id, i)"
                        class="btn btn--border btn--fs"
                      >Отклонить</button>
                      <button
                        @click.stop="acceptInvite(item.id)"
                        class="btn btn--prime btn--fs"
                      >Принять</button>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <Pagination
              v-model="page"
              :length="totalCountPages"
            ></Pagination>
          </template>
        </template>
      </div>
      <router-link
        :to="{ name: $routeName.ASSISTANCE_PROVIDE_APPLICATIONS }"
      >
        <button
          class="btn btn--border btn--fs"
        >Вернуться к заявкам</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Pagination from "@component/UI/Pagination/Pagination";

export default {
  name: "Employees",
  components: {
    Pagination
  },
  data() {
    return {
      loading: false,
      inviteList: [],
      page: 1,
      pageSize: 10,
      totalCountPages: null,
      totalCountItems: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    isCompanyEmployee() {
      return this.user?.my_company;
    }
  },
  methods: {
    ...mapActions({
      setInviteStatus: 'invite/setInviteStatus',
      getUser: 'user/getUser'
    }),
    getInviteList() {
      this.loading = true;
      RequestManager.Invite.getInviteList()
        .then(({ results, count }) => {
          this.totalCountItems = count;
          this.inviteList = results;
          this.totalCountPages = Math.ceil(count / this.pageSize);
        })
        .finally(() => this.loading = false);
    },
    rejectInvite(id, i) {
      this.$modal.show('Confirm', {
        title: 'Вы уверены, что хотите отклонить приглашение?'
      })
        .then((res) => {
          if (!res) return;
          this.setInviteStatus({
            id, status: this.$ENUM.USER_INVITE_STATUSES.REJECTED.KEY
          })
            .then(() => {
              this.getInviteList();
            });
        });
    },
    acceptInvite(id) {
      this.$modal.show('Confirm', {
        title: 'Вы уверены, что хотите принять приглашение?'
      })
        .then((res) => {
          if (!res) return;
          this.setInviteStatus({
            id, status: this.$ENUM.USER_INVITE_STATUSES.JOINED.KEY
          }).then(() => {
            this.$modal.show('Accept', {
              title: 'Поздравляем! Вы стали сотрудником компании.'
            }).then(() => this.getUser());
          });
        });
    }
  },
  mounted() {
    this.getInviteList();
  }
};
</script>

<style lang="scss" scoped>

.list-employees {
  margin-top: 15px;
}

.list-employee {
  margin-bottom: 25px;
}

.list-employee {
  background: #FFFFFF;
  border: 1px solid rgba(118, 118, 128, 0.12);
  border-radius: 10px;
  padding: 47px 26px;
}

.list-employee__title {
  font-size: 25px;
  color: var(--color-prime);
  margin-bottom: 12px;
}

.list-employee__desc {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 27px;
}

.btn-wrapp {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  @include respond-to('md') {
    flex-wrap: nowrap;
  }
}

.btn {
  max-width: 343px;
  margin: 0 5px 0 5px;
  min-height: 56px;
  @media(max-width: 991px) {
    margin-bottom: 15px;
  }
}

.list-employees__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #FFFFFF;
  border: 1px solid rgba(118, 118, 128, 0.2);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.employees-left {
  display: flex;
  align-items: center;
}

.employee-desc {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 5px;
}

.employee-btn-details {
  margin: 5px 0;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 15px;
  margin-bottom: 15px;
  &:hover {
    color: var(--color-prime);
  }
}

.employee-name {
  font-weight: 600;
  font-size: 22px;
  color: #000;
}

.btn {
  margin-left: 15px;
  min-height: 56px;
}
</style>
